<template>
  <div
    class="my_box"
    v-loading="loading"
    element-loading-text="登录中..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <template v-if="hostname !== 'jykw.gz-jiayou.com'">
      <p class="title">欢迎登录</p>
    </template>

    <template v-else>
      <p class="title">职业技能等级认定平台门户网站</p>
      <p class="title" style="padding-top:0">欢迎登录</p>
    </template>
    
    <el-form
      class="my_form"
      :model="form"
      ref="form"
      @keyup.enter.native="handelLogin('form')"
    >
      <el-form-item
        prop="userName"
        :rules="[{required: true, message: '请输入手机号', trigger: 'blur'}]"
      >
        <el-input
          placeholder="手机号"
          v-model.trim="form.userName"
          @input="changeVal"
        >
          <i slot="prefix" class="el-icon-user-solid my_icon"></i>
        </el-input>
      </el-form-item>
      <el-form-item
        prop="password"
        :rules="[{required: true, message: '请输入密码', trigger: 'blur'}]"
      >
        <el-input
          :type="passwordType"
          placeholder="密码"
          @input="changeVal"
          v-model.trim="form.password"
        >
        <i
          class="el-icon-view el-input__icon"
          slot="suffix"
          @click="showPassword"
        ></i>
          <i slot="prefix" class="el-icon-lock my_icon"></i>
        </el-input>
      </el-form-item>

      <div class="other_control font14">
        <span class="color66" @click="resetPwd">忘记密码？</span>
        <span class="skyblue" @click="toLogin">注册</span>
      </div>
      <div class="btn">
        <el-button
          type="primary"
          :class="isDisable ? '' : 'btn_c'"
          @click="handelLogin('form')"
          :disabled="isDisable"
          >登 录</el-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import {login} from "@/util/api";
import {mapMutations} from "vuex";
export default {
  data() {
    return {
      isDisable: true,
      passwordType:'password',
      form: {
        userName: null,
        password: null,
        domainUrl: window.location.hostname,
      },
      hostname:window.location.hostname,
      loading: false,
    };
  },
  methods: {
    ...mapMutations(["changeLogin"]),
    changeVal() {
      if (this.form.userName && this.form.password) {
        this.isDisable = false;
      } else {
        this.isDisable = true;
      }
    },
    showPassword() {
      this.passwordType == ""
        ? (this.passwordType = "password")
        : (this.passwordType = "");
    },
    resetPwd() {
      this.$router.push("/guide/resetPwd");
    },
    toLogin() {
      this.$router.push("/guide/register");
    },
    handelLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          login(this.form)
            .then((res) => {
              this.$notify({
                title: "成功",
                message: "登录成功！",
                type: "success",
              });
              this.changeLogin({Authorization: res.data.token});
              this.loading = false;
              this.$router.replace("/");
            })
            .catch((err) => {
              this.loading = false;
            });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.my_box {
  height: 100%;
}
p.title {
  font-size: 26px;
  color: #333333;
  padding-top: 50px;
  text-align: center;
}
.my_form {
  width: 65%;
  margin: 30px auto;
}
.el-form-item {
  margin-bottom: 30px;
}
.my_icon {
  font-size: 16px;
  margin-left: 20px;
}
.other_control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    cursor: pointer;
  }
}
.btn {
  width: 100%;
  margin-top: 50px;
  button {
    width: 100%;
    border-radius: 25px;
  }
  .btn_c {
    background-color: #5586e1;
  }
}
</style>
<style scoped>
.my_form >>> .el-input--prefix .el-input__inner {
  border-radius: 10px;
  padding-left: 60px;
}

</style>
